function Clients(props) {
  const { aboutData } = props;

  return (
    <div className="about__content-wrapper">
      <h1 className="about__content-title">clients</h1>
      <div className="about__content">{aboutData.clients}</div>
    </div>
  );
}

export default Clients;
