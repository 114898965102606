import React, { useEffect, useState, useRef } from "react";
import Plyr from "plyr-react";
import "./plyr-styles/plyr.scss";
// import { PlayerContext } from '../../hooks/PlayerProvider';

function ProjectYoutube(props) {
  const { media } = props;
  // const { isPlaying, play, pause } = useContext(PlayerContext);
  const [plyrProps, setPlyrProps] = useState({});
  
  const playerWrapper = useRef()
  const player = useRef()

  useEffect(() => {
    setPlyrProps({
      source: {
        type: "video",
        sources: [
          {
            src: media.entryId,
            provider: "youtube",
          },
        ],
      },
      options: {
        controls: ["play", "mute", "progress"],
      },
    });
  }, [media.entryId]);

  const handleScroll = () => {
    let top = playerWrapper.current.getBoundingClientRect().top
    let bottom = top + playerWrapper.current.offsetHeight;
    if(top > window.innerHeight / 2 || bottom < window.innerHeight / 2 ){
      player.current.plyr.pause();
    }
  }

  useEffect(()=>{
    window.addEventListener("scroll", handleScroll);
    return()=> {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  return (
    <>
      <div className="project-media__wrapper" ref={playerWrapper}>
        <Plyr {...plyrProps} ref={player}/>
      </div>
      <div
        className={`project-media__caption-wrapper`}
      >
        {media.entryCaption}
      </div>
    </>
  );
}

export default ProjectYoutube;
