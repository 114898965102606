import "./AboutContent.scss";

function Workshops(props) {
  const { aboutData } = props;

  return (
    <div className="about__content-wrapper">
      <h1 className="about__content-title">creative workshops</h1>
      <div className="about__content">
        {aboutData.creativeWorkshops &&
          aboutData.creativeWorkshops.map((workshop, index) => {
            return (
              <div className="about__content-event" key={index}>
                  <span className="about__content-year">
                    {workshop.entryYear}
                  </span>
                {workshop.entryLocation}
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default Workshops;
