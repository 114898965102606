import React from "react";
import sanityClient from "../../client.js";
import imageUrlBuilder from "@sanity/image-url";
import useProgressiveImg from "../../hooks/useProgressiveImage.js";

const builder = imageUrlBuilder(sanityClient);

function urlFor(source) {
  return builder.image(source);
}

function ProjectImage(props) {
  const { media, projectData } = props;


  const smallUrl = `${urlFor(media.entryImage)}?q=10`;
  const largeUrl = `${urlFor(media.entryImage)}`;
  
    const [src, { blur }] = useProgressiveImg(
    smallUrl,
    largeUrl
  );

  return (
    <>
      <div
        className={`project-media__wrapper ${
          media.entryPosition === "left" || media.entryPosition === "right"
            ? media.entryPosition
            : ""
        }
    `}
      >
        <img
          src={src}
          alt={`Jon Emmony - ${projectData.projectTitle}`}
          className="project-media__image"
          // SameSite={"None"}
          style={{
            filter: blur ? "blur(20px)" : "none",
            transition: blur ? "none" : "filter 0.3s ease-out",
          }}
        />
        {/* <img
        // src={`${urlFor(media.entryImage)}?q=40&blur=1000`}
        // srcSet={urlFor(media.entryImage)}
        alt={`Jon Emmony - ${projectData.projectTitle}`}
        className="project-media__image"
      /> */}
      </div>
      <div
        className={`project-media__caption-wrapper ${
          media.entryPosition === "left" || media.entryPosition === "right"
            ? media.entryPosition
            : ""
        }
    `}
      >
        {media.entryCaption}
      </div>
    </>
  );
}

export default ProjectImage;
