import React, { useEffect, useRef, useState } from "react";
import useWindowSize from "../../hooks/useWindowSize";

import RelatedProject from "./RelatedProject";
import "./RelatedProjects.scss";

function RelatedProjects(props) {
  const windowSize = useWindowSize();
  const { projectData } = props;
  const container = useRef();
  const wrapper = useRef();

  const [centered, setCentered] = useState("");

  useEffect(() => {
    if (projectData.relatedProjects.length <= 3 && windowSize.width >= 750) {
      setCentered("centered");
    } else {
      setCentered("");
    }
  }, [centered, projectData, windowSize.width]);

  // useEffect(() => {
  //   const scrollContainer = wrapper.current;
  //   let delta = 0;
  //   let maxScrollWidth = scrollContainer.scrollWidth - window.innerWidth;

  //   const handleScroll = (e) => {
  //     delta -= e.wheelDelta * 0.3;

  //     if (delta < 0) {
  //       delta = 0;
  //     } else if (delta > maxScrollWidth) {
  //       delta = maxScrollWidth;
  //     }

  //     if (delta > 0) {
  //       e.preventDefault();
  //       scrollContainer.scrollTo({
  //         top: 0,
  //         left: delta,
  //       });
  //     } else if (delta < 0 && scrollContainer.scrollLeft !== 0) {
  //       e.preventDefault();
  //       scrollContainer.scrollTo({
  //         top: 0,
  //         left: delta,
  //       });
  //     }
  //   };

  //   scrollContainer.addEventListener("wheel", handleScroll);
  //   return () => {
  //     scrollContainer.removeEventListener("wheel", handleScroll);
  //   };
  // }, [container, wrapper]);

  return (
    <div className="related-projects__container" ref={container}>
      <div className="related-projects__title">
        {projectData.projectRelatedTitle &&
        projectData.projectRelatedTitle !== null ? (
          <h1>{projectData.projectRelatedTitle}</h1>
        ) : (
          <h1>Related Projects</h1>
        )}
      </div>
      <div
        className={`related-projects__items-wrapper ${centered}`}
        ref={wrapper}
      >
        {projectData.relatedProjects &&
          projectData.relatedProjects.map((project, index) => (
            <RelatedProject project={project} key={index} />
          ))}
      </div>
    </div>
  );
}

export default RelatedProjects;
