import React, { useRef, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useWindowSize from "../../../hooks/useWindowSize";
import "./RegularProject.scss";

const RegularProject = (props) => {
  const { project, listPosition } = props;
  const wrapper = useRef();
  const [direction, setDirection] = useState("");
  const windowSize = useWindowSize();

  const percentageSeen = (element) => {
    const viewportHeight = window.innerHeight;
    const scrollTop = window.scrollY;
    const elementOffsetTop = element.offsetTop;
    const elementHeight = element.offsetHeight;
    const distance = scrollTop + viewportHeight - elementOffsetTop;
    const percentage = distance / ((viewportHeight + elementHeight) / 100);
    return Math.min(100, Math.max(0, percentage)) - 50;
  };

  useEffect(() => {
    setDirection(project.homepageDirection);

    const handleScroll = () => {
      let percentage = percentageSeen(wrapper.current);

      if (windowSize.width >= 750) {
        if (percentage <= 0) {
          //COMING
          if (direction === "left") {
            wrapper.current.style.transform = `translate3d(${
              percentage * 10
            }px,0, 0) scale(${1 - -percentage / 100}, ${
              1 - -percentage / 100
            })`;
          } else if (direction === "right") {
            wrapper.current.style.transform = `translate3d(${
              -percentage * 10
            }px,0, 0) scale(${1 - -percentage / 100}, ${
              1 - -percentage / 100
            })`;
          } else {
            wrapper.current.style.transform = `translate3d(0, 0, 0) scale(${
              1 - -percentage / 100
            }, ${1 - -percentage / 100})`;
          }

          //BLUR
          wrapper.current.style.filter = `blur(${-percentage - 10}px)`;

        } else {
          //GOING
          if (direction === "left") {
            wrapper.current.style.transform = `translate3d(${
              percentage * 10
            }px,0, 0) scale(${1 - percentage / 100}, ${1 - percentage / 100})`;
          } else if (direction === "right") {
            wrapper.current.style.transform = `translate3d(${
              -percentage * 10
            }px,0, 0) scale(${1 - percentage / 100}, ${1 - percentage / 100})`;
          } else {
            wrapper.current.style.transform = `translate3d(0 ,0, 0)scale(${
              1 - percentage / 100
            }, ${1 - percentage / 100})`;
          }
          //BLUR
          wrapper.current.style.filter = `blur(${percentage - 10}px)`;

        }
      } else {
        wrapper.current.style.transform = `translate3d(0, 0, 0) scale(1, 1)`;
        wrapper.current.style.filter = `blur(0px)`;
      }
      if (percentage >= -10 && percentage <= 10) {
        wrapper.current.style.filter = `blur(0px)`;
      }
    };

    const handleResize = () => {
      let percentage = percentageSeen(wrapper.current);

      if (windowSize.width >= 750) {
        if (percentage <= 0) {
          //COMING
          if (direction === "left") {
            wrapper.current.style.transform = `translate3d(${
              percentage * 10
            }px,0, 0) scale(${1 - -percentage / 100}, ${
              1 - -percentage / 100
            })`;
          } else if (direction === "right") {
            wrapper.current.style.transform = `translate3d(${
              -percentage * 10
            }px,0, 0) scale(${1 - -percentage / 100}, ${
              1 - -percentage / 100
            })`;
          } else {
            wrapper.current.style.transform = `translate3d(0, 0, 0) scale(${
              1 - -percentage / 100
            }, ${1 - -percentage / 100})`;
          }
          wrapper.current.style.filter = `blur(${-percentage - 10}px)`;
        } else {
          //GOING
          if (direction === "left") {
            wrapper.current.style.transform = `translate3d(${
              percentage * 10
            }px,0, 0) scale(${1 - percentage / 100}, ${1 - percentage / 100})`;
          } else if (direction === "right") {
            wrapper.current.style.transform = `translate3d(${
              -percentage * 10
            }px,0, 0) scale(${1 - percentage / 100}, ${1 - percentage / 100})`;
          } else {
            wrapper.current.style.transform = `translate3d(0 ,0, 0)scale(${
              1 - percentage / 100
            }, ${1 - percentage / 100})`;
          }
          wrapper.current.style.filter = `blur(${percentage - 10}px)`;
        }
      } else {
        wrapper.current.style.transform = `translate3d(0, 0, 0) scale(1, 1)`;
        wrapper.current.style.filter = `blur(0px)`;
      }
      if (percentage >= -10 && percentage <= 10) {
        wrapper.current.style.filter = `blur(0px)`;
      }
    };

    setTimeout(() => {
      window.addEventListener("scroll", handleScroll);
    }, 500);
    window.addEventListener("resize", handleResize);
    return () => {
      setTimeout(() => {
        window.removeEventListener("scroll", handleScroll);
      }, 500);
      window.removeEventListener("resize", handleResize);
    };
  }, [listPosition, direction, project.homepageDirection, windowSize.width]);

  return (
    <div className={`home__project-wrapper `}>
      {listPosition === 0 && windowSize.width >= 750 ? (
        <div className="home__first-project-wrapper">
          <Link
            to={`projects/${project.projectSlug.current}`}
            className={`home__project-media`}
            ref={wrapper}
            style={{
              filter: "blur(0px)",
              transform: "translate3d(0, 0, 0) scale(1, 1)",
            }}
          >
            {project.coverMediaType === "video" && windowSize.width >= 750 ? (
              <video
                src={project.coverVideo}
                background="true"
                autoPlay
                loop
                muted
                poster={project.coverImage + "?max-w=500"}
              ></video>
            ) : (
              <img
                src={project.coverImage + "?max-w=600&max-h=600"}
                alt={project.projectTitle}
              />
            )}
            <div className="home__mobile-project-title">
              <span className="home__mobile-year">{project.projectYear} </span>
              <span className="home__mobile-title">
                {project.projectTitle} –{" "}
              </span>
              <span className="home__mobile-title">
                {project.projectClient}
              </span>
              {project.categories &&
                project.categories.map((category, index) => (
                  <span className="home__category" key={index}>
                    ({category})
                  </span>
                ))}
            </div>
          </Link>
        </div>
      ) : (
        <Link
          to={`projects/${project.projectSlug.current}`}
          className={`home__project-media`}
          ref={wrapper}
          style={{
            filter: "blur(0px)",
            transform: "translate3d(0, 0, 0) scale(1, 1)",
          }}
        >
          {project.coverMediaType === "video" && windowSize.width >= 750 ? (
            <video
              src={project.coverVideo}
              background="true"
              autoPlay
              loop
              muted
              poster={project.coverImage + "?max-w=500"}
            ></video>
          ) : (
            <img
              src={project.coverImage + "?max-w=600&max-h=600"}
              alt={project.projectTitle}
            />
          )}
          <div className="home__mobile-project-title">
            <span className="home__mobile-year">{project.projectYear} </span>
            <span className="home__mobile-title">
              {project.projectTitle} –{" "}
            </span>
            <span className="home__mobile-title">{project.projectClient}</span>
            {project.categories &&
              project.categories.map((category, index) => (
                <span className="home__category" key={index}>
                  ({category})
                </span>
              ))}
          </div>
        </Link>
      )}
    </div>
  );
};

export default RegularProject;
