import { Link } from "react-router-dom";

const Project = (props) => {
  const { project, setMediaData } = props;

  const handleMouseEnter = () => {

    if(project.coverMediaType === "image"){
      setMediaData({type: project.coverMediaType, src: `${project.coverImage}?=max-w=300&q=30`});
    } else if(project.coverMediaType === "video"){
      setMediaData({type: project.coverMediaType, src: project.coverVideo});
    }
  }

  const handleMouseLeave = () => {
    setMediaData({type: null, src: null})
  }

  return (
    <Link
      to={`/projects/${project.projectSlug.current}`}
      className="archive__project-wrapper"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <span className="archive__project-year">{project.projectYear} </span>
      <span className="archive__project-title">
        {project.projectClient} – {project.projectTitle}{" "}
      </span>
      {project.categories &&
        project.categories.map((category, index) => (
          <span className="archive__project-category" key={index}>
            ({category})
          </span>
        ))}
    </Link>
  );
};

export default Project;
