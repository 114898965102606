function Exhibitions(props) {
  const { aboutData } = props;
  return (
    <div className="about__content-wrapper">
      <h1 className="about__content-title">exhibitions</h1>
      <div className="about__content">
        {aboutData.exhibitions &&
          aboutData.exhibitions.map((exhibition, index) => {
            return (
              <div className="about__content-event" key={index}>
                <span className="about__content-location">{exhibition.entryLocation} ({exhibition.entryYear})</span>
                <br />
                {exhibition.entryEvent}
                <br />
                {exhibition.entryDates}
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default Exhibitions;
