function Awards(props) {
  const { aboutData } = props;

  return (
    <div className="about__content-wrapper">
      <h1 className="about__content-title">awards</h1>
      <div className="about__content">
        {aboutData.awards &&
          aboutData.awards.map((award, index, arr) => {
            return(
            <div className="about__content-event" key={index}>
              <span className="about__content-year">{award.entryYear}</span>
              {award.entryAward}
              <br />
              {award.entryLocation}
            </div>
            );
          })}
      </div>
    </div>
  );
}

export default Awards;
