import React, { useState, useEffect } from "react";
import sanityClient from "../client.js";
import { Helmet } from "react-helmet";

import Header from "../components/about/Header";
import Clients from "../components/about/Clients";
import Workshops from "../components/about/Workshops";
import Awards from "../components/about/Awards.js";
import Exhibitions from "../components/about/Exhibitions.js";
import Talks from "../components/about/Talks.js";

import { motion } from "framer-motion";

function About() {
  const [aboutData, setAboutData] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "about"][0]{
              aboutRole,
              aboutRep,
              aboutContactLinks,
              aboutLinks,
              clients,
              "creativeWorkshops" : creativeWorkshops | order(entryYear desc),
              "awards" : awards | order(entryYear desc),
              "exhibitions" : exhibitions | order(entryYear desc),
              "talks" : talks | order(entryYear desc)
          }`
      )
      .then((data) => setAboutData(data))
      .catch(console.error);
  }, []);

  if (aboutData) {
    return (
      <>
        <Helmet>
          <title>Jon Emmony – About</title>
          {/* <meta name="description" content={metaData.metaDescription} /> */}
        </Helmet>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.4 } }}
          exit={{ opacity: 0, transition: { duration: 0.4 } }}
        >
          <Header aboutData={aboutData} />
          <Clients aboutData={aboutData} />
          <Awards aboutData={aboutData} />
          <Exhibitions aboutData={aboutData} />
          <Talks aboutData={aboutData} />
          <Workshops aboutData={aboutData} />
        </motion.div>
      </>
    );
  }
}

export default About;
