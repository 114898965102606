import React, { useState, useEffect } from "react";
import sanityClient from "../client.js";
import { Helmet } from "react-helmet";
import { AnimatePresence } from "framer-motion";
// import useWindowSize from "../hooks/useWindowSize.js";

import RegularView from "../components/home/regular-view/RegularView.js";
// import AltView from "../components/home/alt-view/AltView.js";

import "../components/home/Home.scss";

const Home = () => {
  const [projectData, setProjectData] = useState({});
  // const [pageView, setPageView] = useState("regular");
  // const windowSize = useWindowSize();

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "home"][0]{
            "projects": projects[]->{
              _key,
              projectSlug,
              projectTitle,
              projectClient,
              projectYear,
              categories,
              homepageDirection,
              coverMediaType,
              "coverImage": coverImage.asset->url,
              "coverVideo": coverVideo.asset->url,
              altViewImages
            }
          }`
      )
      .then((data) => setProjectData(data))
      .catch(console.error);
  }, []);

  // const handleChangeView = () => {
  //   if (pageView === "regular") {
  //     setPageView("alt");
  //   } else {
  //     setPageView("regular");
  //   }
  // };

  return (
    <>
      <Helmet>
        <title>Jon Emmony – Home</title>
      </Helmet>
      {/* {windowSize.width >= 750 ? (
        <button className="home__change-view" onClick={handleChangeView}>
          change view
        </button>
      ) : (
        ""
      )} */}

      {/* {pageView === "regular" ? ( */}
        <AnimatePresence>
          <RegularView
            projectData={projectData}
            key={"regular"}
            // pageView={pageView}
          />
        </AnimatePresence>
      {/* ) : (
        <AnimatePresence mode="wait">
          <AltView key={"alt"} pageView={pageView} />
        </AnimatePresence>
      )} */}
    </>
  );
};

export default Home;

// import React, { useState, useEffect } from "react";
// import sanityClient from "../client.js";
// import { Link } from "react-router-dom";
// import { Helmet } from "react-helmet";

// import { motion, AnimatePresence, useDragControls } from "framer-motion";
// import useWindowSize from "../hooks/useWindowSize";

// import Scrollbar from "../components/home/Scrollbar";
// import Project from "../components/home/Project.js";

// import '../components/home/ProjectAlt.scss';

// function Home() {
//   const [projectData, setProjectData] = useState({});
//   const [exploreState, setExploreState] = useState("closed");
//   const [pageView, setPageView] = useState("regular");
//   const controls = useDragControls()
//   const windowSize = useWindowSize();

//   useEffect(() => {
//     sanityClient
//       .fetch(
//         `*[_type == "home"][0]{
//             "projects": projects[]->{
//               projectSlug,
//               projectTitle,
//               projectClient,
//               projectYear,
//               categories,
//               homepageDirection,
//               coverMediaType,
//               "coverImage": coverImage.asset->url,
//               "coverVideo": coverVideo.asset->url
//             }
//           }`
//       )
//       .then((data) => setProjectData(data))
//       .catch(console.error);
//   }, []);

//   useEffect(() => {
//     const handleScroll = () => {
//       let totalHeight = Math.max(
//         document.body.scrollHeight,
//         document.body.offsetHeight
//       );
//       let scrollPosition = window.scrollY + window.innerHeight;
//       let triggerPoint = totalHeight - 50;

//       if (scrollPosition >= triggerPoint && windowSize.width >= 750) {
//         setExploreState("open");
//       } else {
//         setExploreState("closed");
//       }
//     };

//     window.addEventListener("scroll", handleScroll);
//     return () => {
//       window.removeEventListener("scroll", handleScroll);
//     };
//   }, [windowSize.width]);

//   const handleChangeView = () => {
//     if (pageView === "regular") {
//       setPageView("alt");
//     } else {
//       setPageView("regular");
//     }
//   };

//   function startDrag(event) {
//     controls.start(event, { snapToCursor: false })
//   }

//   return (
//     <>
//       <Helmet>
//         <title>Jon Emmony – Home</title>
//       </Helmet>

//       {pageView === "regular" ? (
//         <AnimatePresence>
//           <motion.main
//             key={"regular"}
//             initial={{ opacity: 0 }}
//             animate={{ opacity: 1, transition: { duration: 0.4 } }}
//             exit={{ opacity: 0, transition: { duration: 0.4 } }}
//           >
//             {windowSize.width >= 750 ? (
//               <Scrollbar projectData={projectData} />
//             ) : (
//               ""
//             )}
//             <button className="home__change-view" onClick={handleChangeView}>
//               change view
//             </button>
//             <div>
//               {projectData.projects &&
//                 projectData.projects.map((project, index) => (
//                   <Project key={index} project={project} listPosition={index} />
//                 ))}
//             </div>
//             <div className={`home__explore-wrapper ${exploreState}`}>
//               <Link to="/archive" className="home__button">
//                 Explore more work in the Archive
//               </Link>
//             </div>
//           </motion.main>
//         </AnimatePresence>
//       ) : (
//         <AnimatePresence>
//           <motion.main
//             key={"alt"}
//             initial={{ opacity: 0 }}
//             animate={{ opacity: 1, transition: { duration: 0.4 } }}
//             exit={{ opacity: 0, transition: { duration: 0.4 } }}
//           >

//             <motion.div
//             drag={true}
//             onPointerDown={startDrag}
//             dragControls={controls}
//             dragConstraints={{ left: 0, right: 0, top:0, bottom: 0 }}
//             dragElastic={0.5}
//             dragSnapToOrigin={false}
//             // dragSnapToCursor={false}
//             dragTransition={{ bounceStiffness: 1, bounceDamping: 10 }}
//             className="home__alt-wrapper top">

//             </motion.div>

//             <button className="home__change-view" onClick={handleChangeView}>
//               change view
//             </button>
//           </motion.main>
//         </AnimatePresence>
//       )}
//     </>
//   );
// }

// export default Home;
