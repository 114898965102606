import React from "react";

import './Title.scss';

function Title(props) {
  const { projectData } = props;

  return (
    <div className="project-title__wrapper">
      <span className="project__title-year">{projectData.projectYear} </span>{projectData.projectClient} – {projectData.projectTitle}  
    </div>
  );
}

export default Title;
