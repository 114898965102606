import React from "react";
import { Link } from "react-router-dom";
import useWindowSize from "../../hooks/useWindowSize";

function RelatedProject(props) {
  const { project } = props;
  const windowSize = useWindowSize();


  return (
    <Link
      to={`/projects/${project.projectSlug.current}`}
      className="related-projects__item-wrapper"
    >
    <div className="related-projects__media-wrapper">
      {project.coverMediaType === "image" || windowSize.width <= 750 ? (
        <img src={project.coverImage} alt={project.projectTitle} />
      ) : (
        <video
          src={project.coverVideo}
          background="true"
          autoPlay
          loop
          muted
          poster={project.coverImage}
        ></video>
      )}
      </div>
      <div className="related-projects__title-wrapper">
        {project.projectTitle} – {project.projectClient}, {project.projectYear}
      </div>
    </Link>
  );
}

export default RelatedProject;
