const ArchiveHeader = (props) => {
  const { filterState, setFilterState, setListVisible, projectData } = props;

  const handleChangeState = (e) => {
    let filter = e.target.dataset.filter;

    setListVisible("disabled");
    setFilterState(filter);
    setTimeout(()=>{
      if (filter === "projectYear") {
        function compare(a, b) {
          if (a[filter] < b[filter]) {
            return 1;
          }
          if (a[filter] > b[filter]) {
            return -1;
          }
          return 0;
        }
        projectData.sort(compare);
      } else {
        function compare(a, b) {
          if (a[filter] < b[filter]) {
            return -1;
          }
          if (a[filter] > b[filter]) {
            return 1;
          }
          return 0;
        }
        projectData.sort(compare);
      }
      setListVisible("visible");
    }, 400)
    

  };

  return (
    <div className="archive__header-wrapper">
        <span className="archive__category header">filter by:</span>
      <button onClick={handleChangeState} data-filter="projectYear" 
      className={`archive__filter-button ${filterState === "projectYear" ? 'active':''}`}>
        Year
      </button>
      <button onClick={handleChangeState} data-filter="projectClient"      
className={`archive__filter-button ${filterState === "projectClient" ? 'active':''}`}>
        Client
      </button>
      <button onClick={handleChangeState} data-filter="projectTitle"
      className={`archive__filter-button ${filterState === "projectTitle" ? 'active':''}`}>
        Title
      </button>
      {/* <span>categories: </span>
      {projectData.categories &&
            projectData.categories.map((category, index) => (
              <span className="archive__project-category" key={index}>( {category} )</span>
            ))} */}
    </div>
  );
};

export default ArchiveHeader;
