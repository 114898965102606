import React, { useEffect, useState } from "react";
import Plyr from "plyr-react";
// import "plyr-react/plyr.css";
import "./plyr-styles/plyr.scss";

function ProjectVimeo(props) {
  const { media } = props;
  const [plyrProps, setPlyrProps] = useState({});

  useEffect(() => {
    setPlyrProps({
      source: {
        type: "video",
        sources: [
          {
            src: media.entryId,
            provider: "vimeo",
          },
        ],
      },
      options: {
        controls: ["play", "mute", "progress"],
      },
    });
  }, [media.entryId]);

  return (
    <>
      <div className="project-media__wrapper">
        <Plyr {...plyrProps} />
      </div>
      <div className={`project-media__caption-wrapper`}>
        {media.entryCaption}
      </div>
    </>
  );
}

export default ProjectVimeo;
