import React, { useState, useRef } from "react";
import { PortableText } from "@portabletext/react";
import useWindowSize from "../../hooks/useWindowSize";
import "./InfoWrapper.scss";

function InfoWrapper(props) {
  const { projectData, scrollPosition } = props;
  const [wrapperState, setWrapperState] = useState("closed");
  const windowSize = useWindowSize();
  const wrapper = useRef();

  const changeWrapperState = () => {
    if (wrapperState === "closed") {
      setWrapperState("open");
      if(scrollPosition === "absolute"){
        window.scrollTo({
          top: document.documentElement.scrollHeight - (window.innerHeight * 1.5 + 300),
          behavior: 'smooth'
      })
      }
    } else {
      setWrapperState("closed");
    }
  };

  const PortableTextComponents = {
    marks: {
      link: ({ value, children }) => {
        const target = (value?.href || "").startsWith("http")
          ? "_blank"
          : undefined;
        return (
          <a
            href={value?.href}
            target={target}
            rel={target === "_blank" && "noindex nofollow"}
          >
            {children}
          </a>
        );
      },
    },
  };

  return (
    <div
      className={`info-wrapper__wrapper ${wrapperState} 
      ${projectData.slideshow.length > 1 || windowSize.width >= 750?(
          scrollPosition
        ): (
          "absolute"
        )
      }`}
      onClick={changeWrapperState}
      ref={wrapper}
    >
      project information
      <span className="info-wrapper__button">
        {wrapperState === "closed" ? "+" : "-"}
      </span>
      <div className={`info-wrapper__content-wrapper ${wrapperState}`}>
        <PortableText
          value={projectData.projectInfo}
          components={PortableTextComponents}
        />
        {projectData.credits.map((credit, index) => (
          <div className="info-wrapper__credit-wrapper" key={index}>
            {credit.entryRole}<br/>
            {credit.entryPerson}
          </div>
        ))}
      </div>
    </div>
  );
}

export default InfoWrapper;
