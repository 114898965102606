import React, { useEffect, useRef, useState } from "react";
import "./ProjectScrollbar.scss";

function ProjectScrollbar(props) {
  const { projectData } = props;
  const indexWrapper = useRef();

  const [borderState, setBorderState] = useState("");
  const border = useRef();

  useEffect(() => {
    const handleScroll = () => {
      indexWrapper.current.scrollTo({
        top: window.scrollY / 10,
        left: 0,
      });
      if(window.scrollY >= document.documentElement.scrollHeight - window.innerHeight){
        setBorderState("disabled")
      } else {
        setBorderState("visible")
      }

    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [indexWrapper]);

  return (
    <>
      <div className="project-scrollbar__total">
        / {projectData.slideshow.length}
      </div>
      <div className={`project-scrollbar__index-border ${borderState}`} ref={border}></div>
      <div className="project-scrollbar__index-wrapper" ref={indexWrapper}>
        {projectData.slideshow &&
          projectData.slideshow.map((project, index) => (
            <div className="project-scrollbar__index-divide" key={index}>
              <div className="project-scrollbar__index-number">{index + 1}</div>
            </div>
          ))}
      </div>
    </>
  );
}

export default ProjectScrollbar;
