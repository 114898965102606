import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

import Scrollbar from "./Scrollbar";
import RegularProject from "./RegularProject";

import "./RegularView.scss";

import useWindowSize from "../../../hooks/useWindowSize";

const RegularView = (props) => {
  const { projectData, pageView } = props;
  const [exploreState, setExploreState] = useState("closed");
  const windowSize = useWindowSize();

  useEffect(() => {
    const handleScroll = () => {
      let totalHeight = Math.max(
        document.body.scrollHeight,
        document.body.offsetHeight
      );
      let scrollPosition = window.scrollY + window.innerHeight;
      let triggerPoint = totalHeight - 50;

      if (scrollPosition >= triggerPoint && windowSize.width >= 750) {
        setExploreState("open");
      } else {
        setExploreState("closed");
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [windowSize.width]);

  if (projectData) {
    return (
      <>
        <motion.main
          key={"regular"}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.4 } }}
          exit={{opacity: 0,transition: {duration: 0.4,}}}
        >
          {windowSize.width >= 750 ? (
            <Scrollbar projectData={projectData} />
          ) : (
            ""
          )}

          <div className={`home__regular-view-wrapper ${pageView}`}>
            {projectData.projects &&
              projectData.projects.map((project, index) => (
                <RegularProject
                  key={index}
                  project={project}
                  listPosition={index}
                />
              ))}
          </div>
          <div className={`home__explore-wrapper ${exploreState}`}>
            <Link to="/archive" className="home__button">
              Explore more work in the Archive
            </Link>
          </div>
        </motion.main>
      </>
    );
  } else {
    <motion.main
      key={"regular"}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.4 } }}
      exit={{ opacity: 0, transition: { duration: 0.4 } }}
    >
      <div>LOADING</div>
    </motion.main>;
  }
};

export default RegularView;
