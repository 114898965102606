import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import sanityClient from "../client.js";
import { Helmet } from "react-helmet";

import { motion } from "framer-motion";

import Error from "./Error.js";
import Title from "../components/project/Title.js";
import ProjectContent from "../components/project/ProjectContent.js";
import RelatedProjects from "../components/project/RelatedProjects.js";
import ProjectScrollbar from "../components/project/ProjectScrollbar.js";
import useWindowSize from "../hooks/useWindowSize";

function Project() {
  const [projectData, setProjectData] = useState(null);
  const [noProjectExists, setNoProjectExists] = useState(false);
  const { slug } = useParams();
  const windowSize = useWindowSize();

  useEffect(() => {
    sanityClient
      .fetch(
        `*[projectSlug.current == $slug]{
              projectTitle,
              projectSlug,
              projectClient,
              projectYear,
              projectInfo,
              coverMediaType,
              "coverImage": coverImage.asset->url,
              "coverVideo": coverVideo.asset->url,
              credits,
              slideshow,
              projectRelatedTitle,
              "relatedProjects" : relatedProjects[]->{
                projectSlug,
                projectTitle,
                projectClient,
                projectYear,
                coverMediaType,
                "coverImage": coverImage.asset->url,
                "coverVideo": coverVideo.asset->url
              }
          }`,
        { slug }
      )
      .then((data) => {
        setProjectData(data[0]);
        if (data.length === 0) {
          setNoProjectExists(true);
        }
      })
      .catch();
  }, [slug]);

  if (projectData) {
    return (
      <>
        <Helmet>
          <title>
            Jon Emmony – {projectData.projectTitle}, {projectData.projectClient}
          </title>
          <meta name="description" content={projectData.projectInfo} />
        </Helmet>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.4 } }}
          exit={{ opacity: 0, transition: { duration: 0.4 } }}
        >
          <Title projectData={projectData}></Title>
          <ProjectContent projectData={projectData} />
          <RelatedProjects projectData={projectData} />
          {projectData.slideshow.length > 1 && windowSize.width >= 750? (
            <ProjectScrollbar projectData={projectData} />
          ) : (
            ""
          )}
        </motion.div>
      </>
    );
  } else if (noProjectExists === true) {
    return <Error />;
  } else {
    return null;
  }
}

export default Project;
