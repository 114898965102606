import React, { useRef, useEffect } from "react";

import "./Scrollbar.scss";

function Scrollbar(props) {
  const { projectData } = props;

  const scrollbarWrapper = useRef();
  const indexWrapper = useRef();
  const titleWrapper = useRef();

  useEffect(() => {
    let ammount;

    const handleScroll = () => {
      if(window.innerWidth >= 750){
        ammount = 10
      } else {
        ammount = 4
      };
        scrollbarWrapper.current.scrollTo({
          top: window.scrollY / ammount,
          left: 0,
        });
        indexWrapper.current.scrollTo({
          top: window.scrollY / ammount,
          left: 0,
        });
        titleWrapper.current.scrollTo({
          top: window.scrollY / ammount,
          left: 0,
        });
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollbarWrapper, indexWrapper, titleWrapper]);

  return (
    <>
      <div className="scrollbar__wrapper" ref={scrollbarWrapper}>
        <div className="scrollbar__vertical"></div>
        {projectData.projects &&
          projectData.projects.map((project, index) => (
            <div className="scrollbar__divide" key={index}></div>
          ))}
        {/* <div className="scrollbar__divide"></div> */}
      </div>

      <div className="scrollbar__index-wrapper" ref={indexWrapper}>
        {projectData.projects &&
          projectData.projects.map((project, index) => (
            <div className="scrollbar__index-divide" key={index}>
              <div className="scrollbar__index-number">{index + 1}</div>
            </div>
          ))}
        {/* <div className="scrollbar__index-divide">
          <div className="scrollbar__index-number">&#10019;</div>
        </div> */}
      </div>

      {projectData.projects ? (
        <div className="scrollbar__total-number">
          / {projectData.projects.length}
        </div>
      ) : (
        " "
      )}

      <div className="scrollbar__title-wrapper" ref={titleWrapper}>
        {projectData.projects &&
          projectData.projects.map((project, index) => (
            <div className="scrollbar__title-divide" key={index}>
              <div className="scrollbar__title">
                {project.projectTitle} – {project.projectClient},{" "}
                {project.projectYear}
              </div>
            </div>
          ))}
        {/* <div className="scrollbar__title-divide">
          <div className="scrollbar__title">
            </div>
        </div> */}
      </div>
    </>
  );
}

export default Scrollbar;
