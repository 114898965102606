import { useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import MetaData from "./components/global/metaData";
import Home from "./views/Home";
// import HomeAlt from "./views/HomeAlt";
import About from "./views/About";
import Archive from "./views/Archive";
import Project from "./views/Project";
import Error from "./views/Error";
import Header from "./components/global/Header";

import { AnimatePresence } from "framer-motion";

import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

import "./styles/global-styles.scss";

function App() {
  const [headerState, setHeaderState] = useState("");
  const location = useLocation();

  const [metaData, setMetaData] = useState(null);

  const firebaseConfig = {
    apiKey: "AIzaSyDRKu_hPWc2yQW7BWv48leeJ4MDu_-GlJg",
    authDomain: "jon-emmony-website.firebaseapp.com",
    projectId: "jon-emmony-website",
    storageBucket: "jon-emmony-website.appspot.com",
    messagingSenderId: "718626122336",
    appId: "1:718626122336:web:0b1a313ab6b2beb9cf3105",
    measurementId: "G-V9CJQ4EF3D",
  };
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  logEvent(analytics, 'notification_received');
  
  return (
    <>
      <MetaData metaData={metaData} setMetaData={setMetaData}></MetaData>
      <Header headerState={headerState} setHeaderState={setHeaderState} />
      <AnimatePresence>
        <Routes location={location} key={location.pathname}>
          <Route element={<Home key={"home"} />} path="/" metaData={metaData} />
          {/* <Route
          element={<HomeAlt key={"homeAlt"} />}
          path="/alt"
        /> */}
          <Route element={<About key={"about"} />} path="/about" />

          <Route element={<Project key={"about"} />} path="/projects/:slug" />

          <Route element={<Archive key={"archive"} />} path="/archive" />

          <Route element={<Error key={"error"} />} path="*" />
        </Routes>
      </AnimatePresence>
    </>
  );
}

export default App;
