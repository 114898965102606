import { useRef } from "react";
import { useEffect } from "react";

const MouseMedia = (props) => {

    const {mediaData} = props;
    const wrapper = useRef();

    useEffect(()=>{

        let mouse = {
            x: 0,
            y: 0
        }
        const handleMouseMove = (e) =>{
            mouse = {
                x: e.clientX,
                y: e.clientY,
            }

            wrapper.current.style.left = `${mouse.x + 20}px`;
            wrapper.current.style.top = `${mouse.y + 20}px`;

            if(mouse.x > window.innerWidth - 320){
                wrapper.current.style.left = `${mouse.x - 320}px`;
            }
            if (mouse.y > window.innerHeight - 170){
                wrapper.current.style.top = `${mouse.y - 170}px`;
            }

        }

        window.addEventListener('mousemove', handleMouseMove, false);
        return () => {
            window.removeEventListener('mousemove', handleMouseMove, false);
        };
    },[])

  return (
    <div className="archive__media-wrapper" ref={wrapper}>
        <img src={mediaData.src} className={`archive__media ${mediaData.type === "image" && mediaData.src !== ""?"active":""}`} alt="Jon Emmony"/>
        <video src={mediaData.src} className={`archive__media ${mediaData.type === "video" && mediaData.src !== ""?"active":""}`} autoPlay muted background inline loop/>
    </div>
  )
}

export default MouseMedia