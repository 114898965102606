import React from "react";

function Header(props) {
  const { aboutData } = props;

  return (
    <div className="about__header-wrapper">
      <div className="about__header-column-wrapper">
        <div className="about__link-wrapper">
          <span className="about__link-header"></span>
          <span className="about__link-link">
            {aboutData.aboutRole}
          </span>
        </div>
        <div className="about__link-wrapper">
          <span className="about__link-header">represented by:&nbsp;</span>
          <span className="about__link-link">
            {aboutData.aboutRep}
          </span>
        </div>
        {aboutData.aboutLinks &&
          aboutData.aboutLinks.map((link, index) => (
            <div className="about__link-wrapper" key={index}>
            <span className="about__link-header">{link.entryName}:&nbsp;</span>
            <a className="about__link-link" href={link.entryLink} target="_blank" rel="noreferrer">
            {link.entryText}
            </a>
          </div>
          ))}
      </div>
      <div className="about__header-column-wrapper">
      {aboutData.aboutContactLinks &&
          aboutData.aboutContactLinks.map((link, index) => (
            <div className="about__link-wrapper" key={index}>
            <span className="about__link-header">{link.entryName}:&nbsp;</span>
            <a className="about__link-link" href={`mailto:${link.entryLink}`}>
            {link.entryLink}
            </a>
          </div>
          ))}
      </div>
      {/* <div className="about__header-column-wrapper">
      {aboutData.aboutLinks &&
          aboutData.aboutLinks.map((link, index) => (
            <div className="about__link-wrapper" key={index}>
            <span className="about__link-header">{link.entryName}:&nbsp;</span>
            <a className="about__link-link" href={link.entryLink} target="_blank" rel="noreferrer">
            {link.entryText}
            </a>
          </div>
          ))}
      </div> */}
      <div className="about__brackets-wrapper"></div>
    </div>
  );
}

export default Header;
