import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import sanityClient from "../../client.js";
// import imageUrlBuilder from "@sanity/image-url";

function MetaData(props) {
  const {metaData, setMetaData} = props;

//   const builder = imageUrlBuilder(sanityClient);

//   function urlFor(source) {
//     return builder.image(source);
//   }

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "seo"][0]{
                metaDescription,
                metaKeywords,
                metaAnalytics,
                metaFavicon,
                metaImage
            }`
      )
      .then((data) => setMetaData(data))
      .catch(console.error);
  }, [setMetaData]);

  if(metaData){
    return (
        <Helmet>
          <title>Jon Emmony</title>
          <meta name="description" content={metaData.metaDescription} />
          {/* <link
            rel="apple-touch-icon"
            sizes="180x180"
            href={urlFor(metaData.metaFavicon)}
          />
          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href={urlFor(metaData.metaFavicon)}
          />
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href={urlFor(metaData.metaFavicon)}
          /> */}
        </Helmet>
      );
  }
}

export default MetaData;
