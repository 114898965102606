import React, { useState, useEffect, useRef } from "react";
import sanityClient from "../../client.js";
import { NavLink, useLocation } from "react-router-dom";
import useWindowSize from "../../hooks/useWindowSize";
import "./Header.scss";

function Header() {
  
  const [postData, setPostData] = useState(null);
  const [menuState, setMenuState] = useState("menu");
  const windowSize = useWindowSize();
  const button = useRef();
  let location = useLocation();

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "about"]{
          aboutShop
        }`
      )
      .then((data) => setPostData(data))
      .catch(console.error);
  }, []);

  const handleMenuState = () => {
    if(menuState === "menu"){
      setMenuState("close");
    } else {
      setMenuState("menu");
    }
  }

  if (windowSize.width >= 750) {
    return (
      <header>
        <NavLink to={"/"} className="header__jon">
          jon emmony
        </NavLink>
        <NavLink to="/about" className="header__about">
          about
        </NavLink>
        {postData &&
          postData.map((post, index) => (
            <a
              href={postData[0].aboutShop}
              target="blank"
              className="header__shop"
              key={index}
            >
              shop
            </a>
          ))}

        {location.pathname.indexOf("/projects") === 0 ? (
          ""
        ) : (
          <NavLink to={"/"} className="header__work">
            work
          </NavLink>
        )}

        <NavLink to={"/archive"} className="header__archive">
          archive
        </NavLink>
      </header>
    );
  } else {
    return (
      <>
        <NavLink to={"/"} className="header__jon">
          jon emmony
        </NavLink>
        <button className="header__menu-button" ref={button} onClick={handleMenuState}>{menuState}</button>
        <div className={`header__menu-wrapper ${menuState}`} onClick={handleMenuState}>
          <NavLink to="/" className="header__menu-item" onClick={handleMenuState}>
            Home
          </NavLink>
          <NavLink to="/about" className="header__menu-item" onClick={handleMenuState}>
            About
          </NavLink>
          <NavLink to="/archive" className="header__menu-item" onClick={handleMenuState}>
            Archive
          </NavLink>
          {postData &&
            postData.map((post, index) => (
              <a
                href={postData[0].aboutShop}
                target="blank"
                className="header__menu-item"
                key={index} onClick={handleMenuState}
              >
                Shop
              </a>
            ))}
        </div>
      </>
    );
  }
}

export default Header;
