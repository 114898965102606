import React, { useEffect, useState } from "react";
import sanityClient from "../client.js";
import { Helmet } from "react-helmet";

import ArchiveHeader from "../components/archive/ArchiveHeader.js";
import Project from "../components/archive/Project.js";
import "../components/archive/Archive.scss";
import MouseMedia from "../components/archive/MouseMedia.js";

import { motion } from "framer-motion";
import useWindowSize from "../hooks/useWindowSize";

function Archive() {
  const [projectData, setProjectData] = useState([]);
  const [filterState, setFilterState] = useState("projectYear");
  const [listVisible, setListVisible] = useState("visible");
  const [mediaData, setMediaData] = useState({ type: "", src: "" });
  const windowSize = useWindowSize();
  
  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "projects"] | order( projectYear desc){
          coverMediaType,
          "coverImage": coverImage.asset->url,
          "coverVideo": coverVideo.asset->url,
          categories,
          projectClient,
          projectTitle,
          projectSlug,
          projectYear
        }`
      )
      .then((data) => setProjectData(data))
      .catch(console.error);
  }, []);


  return (
    <>
      <Helmet>
        <title>Jon Emmony – Archive</title>
      </Helmet>
      {windowSize.width >= 750 ?  <MouseMedia mediaData={mediaData} /> : ""}
      <motion.main
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { duration: 0.4 } }}
        exit={{ opacity: 0, transition: { duration: 0.4 } }}
      >
        <ArchiveHeader
          filterState={filterState}
          setFilterState={setFilterState}
          projectData={projectData}
          setListVisible={setListVisible}
        />
        <div className={`archive__projects-wrapper ${listVisible}`}>
          {projectData &&
            projectData.map((project, index) => {
              return (
                <Project
                  project={project}
                  mediaData={mediaData}
                  setMediaData={setMediaData}
                  key={index}
                />
              );
            })}
        </div>
      </motion.main>
    </>
  );
}

export default Archive;
