import React, { useState, useEffect } from "react";
import sanityClient from "../client.js";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import { motion } from "framer-motion";

function Error() {
  const [errorData, setErrorData] = useState([]);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "seo"][0]{
              fourText
          }`
      )
      .then((data) => setErrorData(data))
      .catch(console.error);
  }, []);

  return (
    <>
      <Helmet>
        <title>Jon Emmony – Error 404</title>
      </Helmet>
      <motion.div
        className="error__cta-wrapper"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { duration: 0.4 } }}
        exit={{ opacity: 0, transition: { duration: 0.4 } }}
      >
        {errorData.fourText ? (
          <h1>
            <Link to="/" className="error__button">
              {errorData.fourText}
            </Link>
          </h1>
        ) : (
          ""
        )}
      </motion.div>
    </>
  );
}

export default Error;
