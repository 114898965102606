function Talks(props) {
  const { aboutData } = props;
  return (
    <div className="about__content-wrapper">
      <h1 className="about__content-title">creative talks</h1>
      <div className="about__content">
        {aboutData.talks &&
          aboutData.talks.map((talk, index) => {
            return (
              <div className="about__content-event" key={index}>
                
                  <span className="about__content-year">
                    {talk.entryYear}
                  </span>
                {talk.entryLocation}
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default Talks;
