import React, { useState, useEffect, useRef } from "react";

import ProjectImage from "./ProjectImage";
import ProjectYoutube from "./ProjectYoutube";
import ProjectVimeo from "./ProjectVimeo";
import InfoWrapper from "./InfoWrapper";
import "./ProjectMedia.scss";

function ProjectContent(props) {
  const { projectData } = props;
  const [scrollPosition, setScrollPosition] = useState("fixed");
  const wrapper = useRef();

  useEffect(() => {
    const handleScroll = () => {
      let bottom =
        wrapper.current.getBoundingClientRect().top +
        wrapper.current.offsetHeight;
      if (bottom <= window.innerHeight) {
        setScrollPosition("absolute");
      } else {
        setScrollPosition("fixed");
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [wrapper, projectData]);

  return (
    <>
      <div className="project-media__container" ref={wrapper}>
        <InfoWrapper
          projectData={projectData}
          scrollPosition={scrollPosition}
        ></InfoWrapper>
        {projectData.slideshow &&
          projectData.slideshow.map((media, index) => {
            if (media._type === "slideshowImage") {
              return (
                <ProjectImage
                  projectData={projectData}
                  media={media}
                  key={index}
                />
              );
            } else if (media._type === "youtubeEmbed") {
              return (
                <ProjectYoutube
                  projectData={projectData}
                  media={media}
                  key={index}
                />
              );
            } else if (media._type === "vimeoEmbed") {
              return (
                <ProjectVimeo
                  projectData={projectData}
                  media={media}
                  key={index}
                />
              );
            } else {
              return null;
            }
          })}
      </div>
    </>
  );
}

export default ProjectContent;
